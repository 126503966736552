(function($) {
	'use strict';

    $(".home-carousel").owlCarousel({
        loop: true,
        autoPlay: true,
        autoplayTimeout: 300,
        autoplayHoverPause: true,
        items: 1,
        nav: false,
        dots: false
    });

    $(".client-carousel").owlCarousel({
        loop: true,
        autoPlay: true,
        autoplayTimeout: 300,
        autoplayHoverPause: true,
        items: 1,
        nav: true,
        navText: ["",""],
        navClass: ["client-carousel__prev", "client-carousel__next"],
        dots: false
    });

    // Scroll top top button
	$("#go-top").click(function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		return false;
	});

    $(window).scroll(function() {
        // Check to see if the window is top if not then display button
        if ($(this).scrollTop() > 100) {
            $("#go-top").fadeIn();
        } else {
            $("#go-top").fadeOut();
        };
    });

    // Chi tiet room slider
    // reference for main items
    var slider = $('.detail-carousel');
    // reference for thumbnail items
    var thumbnailSlider = $('.thumb-carousel');

    function addCurrent() {
        var slideIndex = $(".detail-carousel .owl-item.active").index();
        var thumbItem = $(".thumb-carousel .owl-item");

        thumbItem.removeClass("current");
        $(".thumb-carousel .owl-item:eq(" + slideIndex + ")").addClass("current");
    };

    //transition time in ms
    var duration = 300;
    // carousel function for main slider
    slider.owlCarousel({
        loop: false,
        items: 1,
        nav: false,
        dots: false
    }).on('changed.owl.carousel', function(e) {
        //On change of main item to trigger thumbnail item
        thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
    }).on('dragged.owl.carousel', function(e) {
        addCurrent();
    });
    // carousel function for thumbnail slider
    thumbnailSlider.owlCarousel({
        items: 5,
        loop: false,
        margin: 10,
        dots: false,
        nav: true,
        navText: ["",""],
        navClass: ["thumb-carousel__prev", "thumb-carousel__next"]
    }).on('click', '.owl-item', function() {
        // On click of thumbnail items to trigger same main item
        slider.trigger('to.owl.carousel', [$(this).index(), duration, true]);
        addCurrent();
    }).on('changed.owl.carousel', function(e) {
        // On change of thumbnail item to trigger main item
        slider.trigger('to.owl.carousel', [e.item.index, duration, true]);
        addCurrent();
    });
    //These two are navigation for main items
    $('.thumb-carousel__prev').click(function(e) {
        e.preventDefault();

        if($(".thumb-carousel__prev").hasClass("disabled") === false) {
            slider.trigger('prev.owl.carousel');
            addCurrent();
        } else {
            // Do nothing
        };
    });
    $('.thumb-carousel__next').click(function(e) {
        e.preventDefault();

        if($(".thumb-carousel__next").hasClass("disabled") === false) {
            slider.trigger('next.owl.carousel');
            addCurrent();
        } else {
            // Do nothing
        };
    });

    // Tabs
    $(".tabs-link a").click(function(e) {
        e.preventDefault();
        $(this).parent("li").addClass("active");
        $(this).parent("li").siblings().removeClass("active");

        $($(this).attr("href")).show();
        $($(this).attr("href")).siblings(".tabs-panel").hide();
    });

    addCurrent();

    //Responsive
    if($(window).width() < 992) {
        $(".nav-top").appendTo(".menu-bar");
    };

    $(".exit-menu-bar").click(function(e) {
        e.preventDefault();
        $(".menu-bar").fadeOut();
    });

    $(".menu-btn").click(function(e) {
        e.preventDefault();
        $(".menu-bar").fadeIn();
    });

    $(".open-sub").click(function(e) {
        e.preventDefault();
        $(this).next(".menu__sub").slideToggle();
        $(this).children("i").toggleClass("fa-plus-square");
        $(this).children("i").toggleClass("fa-minus-square");
    });

    $("[data-fancybox]").fancybox({
        thumbs : {
            autoStart : true
        }
    });

    //fixied menu
    $(window).scroll(function() {
        if ($(this).scrollTop() > 0 && $(this).width() >= 992) {
            $(".nav-top").addClass("fixed");
        } else {
            $(".nav-top").removeClass("fixed");
        };

        // if ($(this).scrollTop() > $(".menu-bar").height() + $(".nav-top").height() && $(this).width() >= 992) {
        //     $(".menu-bar").addClass("fixed");
        //     $(".menu-bar").css("top", $(".nav-top").outerHeight());
        // } else {
        //     $(".menu-bar").removeClass("fixed");
        // };
    });

    new WOW().init();

})(jQuery);